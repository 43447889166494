import { Link } from "@reach/router";
import React from "react";
import { Collage, CollageType } from "../components/collages/Collage";
import { GifCollage } from "../components/collages/GifCollage";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TextContent from "../components/shared/TextContent";

export default function PixelArtPage() {
  return (
    <Layout>
      <SEO title="Pixel Art" />
      <GifCollage type={CollageType.PixelArt} />
      <Collage type={CollageType.PixelArt} />
      <TextContent>
        <h1>Pixel Art</h1>
        <p>
          These works include both personal pieces, and works created for
          collaberative game making.
        </p>
        <p>Also check out:</p>
        <ul>
          <li>
            <Link to="/monkey-spin">Monkey Spin</Link>
          </li>
          <li>
            <a href="https://gamejolt.com/games/werewolf-detective/713205">
              Werewolf Detective
            </a>
          </li>
          <li>
            <a href="https://gamejolt.com/games/lemonadeapocalypse/637077">
              Lemonade Apocalypse
            </a>
          </li>
        </ul>
      </TextContent>
    </Layout>
  );
}
